<template>
    <div>
        <!-- Main Layer -->
        <div class="page-sub-box tree-box tree-contents">
            <div class="layout-cut-box clearfix tree-box00">
                <!-- Left Tree Layer -->
                <div class="layout-cut-left tree-box01 fl">
                    <div class="page-sub-box pad_top20">
                        <div class="selectDiv">
                            <DxCheckBox
                                ref="useCheckBox"
                                class="checkbox"
                                text="사용중인 템플릿만 보기"
                                :value="this.checkBoxValue"
                                @value-changed="isUsedFlag"
                            />
                        </div>
                        <esp-dx-data-grid :data-grid="dataGrid" ref="templatesRef" @row-click="onClickTreeRow" />
                    </div>
                </div>
                <!-- /Left Tree Layer -->

                <!-- Right Layer -->
                <div class="layout-cut-right tree-box02 fr">
                    <template>
                        <EspTreeGrid
                            :tree-grid="treeGrid"
                            ref="serviceList"
                            @init-new-row="onInitNewRow"
                            @row-removed="onRowRemoved"
                            @onEditCanceling="onEditCanceling"
                            @row-saved="onSaved"
                        />
                    </template>
                </div>
                <!-- /Right Layer -->
            </div>
        </div>
        <!-- /Main Layer -->

        <!-- Modal Layer -->
        <DxPopup
            :show-title="true"
            :title="modal.initData      ? modal.initData.title  : null"
            :min-width="modal.initData  ? modal.initData.width  : null"
            :width="modal.initData      ? modal.initData.width  : null"
            :min-height="modal.initData ? modal.initData.height : null"
            :height="modal.initData     ? modal.initData.height : null"
            :drag-enabled="true"
            :resize-enabled="true"
            :show-close-button="true"
            :close-on-outside-click="false"
            v-model="modal.isOpened"
            :visible="modal.isOpened"
            @hiding="isOpenModal(false)"
        >
            <template #content>
                <div>
                    <component
                        :is="modal.currentComponent"
                        :templateContent="modal.templateContent"
                        :maxOrd="modal.maxOrd"
                        :allMenus="view.allMenus"
                        :method="modal.currentSaveType"
                        v-model="modal.templateContent"
                    >
                    </component>
                </div>
            </template>

            <DxToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="center"
                :visible="modal.initData.hasOwnProperty('buttons') ? ( modal.initData.buttons.hasOwnProperty('save') ? modal.initData.buttons.hasOwnProperty('save') : !modal.initData.buttons.hasOwnProperty('save') )  : false"
                :options="{
                        elementAttr: {
                            class: 'default filled txt_S medium',
                        },
                        text: modal.initData.hasOwnProperty('buttons') ? ( modal.initData.buttons.hasOwnProperty('save') ? modal.initData.buttons.save.text : '' ) : '',
                        //type: 'default',
                        width: '120',
                        height: '40',
                        useSubmitBehavior: true,
                        onClick: (e) => {
                            onConfirmModal(e);
                        }
                    }"
            />

            <DxToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="center"
                :visible="modal.initData.hasOwnProperty('buttons') ? ( modal.initData.buttons.hasOwnProperty('cancel') ? modal.initData.buttons.hasOwnProperty('cancel') : !modal.initData.buttons.hasOwnProperty('cancel') ) : false"
                :options="{
                        elementAttr: {
                            class: 'white filled txt_S medium',
                        },
                        text: modal.initData.hasOwnProperty('buttons') ? ( modal.initData.buttons.hasOwnProperty('cancel') ? modal.initData.buttons.cancel.text : '' ) : '',
                        width: '120',
                        height: '40',
                        onClick: () => {
                            closeModalAndReloadData();
                        }
                    }"
            />
        </DxPopup>
        <!-- /Modal Layer -->
    </div>
</template>

<script>
import {DxPopup, DxToolbarItem} from 'devextreme-vue/popup';
import {DxPopover} from 'devextreme-vue/popover';
import {DxColumn, DxSelection, DxSorting, DxRequiredRule, DxLookup} from 'devextreme-vue/data-grid';
import {DxSearchPanel, DxRowDragging, DxEditing, DxScrolling, DxFilterRow} from 'devextreme-vue/tree-list';
import {DxButton} from 'devextreme-vue/button';
import {DxSelectBox} from 'devextreme-vue/select-box';
import DxDropDownButton from 'devextreme-vue/drop-down-button';

import ModalTemplate from '@/pages/cc/ivr/service-templates/modal-template.vue';
import {isSuccess} from "@/plugins/common-lib";
import {DxCheckBox} from "devextreme-vue/check-box";
import EspTreeGrid from "../../../../components/devextreme/esp-dx-tree-grid.vue";
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";

export default {
    components: {
      EspDxDataGrid,
        EspTreeGrid,
        DxCheckBox,
        DxSearchPanel,
        DxRowDragging,
        DxPopup,
        DxPopover,
        DxToolbarItem,
        DxColumn,
        DxSelection,
        DxSorting,
        DxRequiredRule,
        DxLookup,
        DxEditing,
        DxScrolling,
        DxFilterRow,
        DxButton,
        DxSelectBox,
        DxDropDownButton,
        ModalTemplate,
    },
    props: {
        columnsDesc: Array,
    },
    watch: {},
    data() {
        return {
            stylingMode: 'outlined',
            // Config object
            config: {
                api: {
                    serviceTemplatesGet: 'CC_IVR_SVC_TP_LIST',
                    serviceTemplatesInsert: 'CC_IVR_SVC_TP_MERGE',
                    serviceTemplatesDelete: 'CC_IVR_SVC_TP_DELETE',
                    serviceTemplatesUpdate: 'CC_IVR_SVC_TP_MERGE',
                    ivrSvcUpdate: 'CC_IVR_SVC_MERGE',
                    ivrSvcDelete: 'CC_IVR_SVC_DELETE',
                    ivrDnisGet: 'CC_IVR_DNIS_LIST',
                },
                mode: {
                    insert: "INSERT",           // 메뉴 등록 시
                    update: "UPDATE",           // 메뉴 수정 시
                },
                isPopover: false,
            },
            treeGrid: {
                id: 'serviceList',
                refName: 'serviceList',
                callApi: "CALL_CC_API",
                dataSource: [],
                expandedRowKeys: [],
                selectedRowkeys: [],
                apiActionNm: {
                    // select: "CC_IVR_DNIS_LIST",
                    merge: "CC_IVR_SVC_MERGE",
                    delete: "CC_IVR_SVC_DELETE"
                },
                focusedRowKey: null,
                showColumnHeaders: true,
                showRowLines: true,
                showColumnLines: true,
                showBorders: false,
                columnAutoWidth: true,
                keyExpr: "id",
                sort: "+servicecodeOrd",
                sortKey: "servicecodeOrd",
                parentIdExpr: "parentId",
                width: null,
                height: null,
                searchPanel: {
                    visible: false,
                    placeholder: "",
                    searchVisibleColumnsOnly: true,
                    width: 200
                },
                filterRow: {
                    visible: true
                },
                editing: {
                    allowAdding: true,
                    allowUpdating: true,
                    useIcons: true,
                    mode: "batch"
                },
                selection: {
                    recursive: true
                },
                rowDragging: {
                    allowDropInsideItem: true,
                    allowReordering: true,
                    showDragIcons: true
                },
                saveButtonId: 'addRootBtn',
                columns: [
                    {
                        caption: "id",
                        dataField: "id",
                        allowSorting: false,
                        alignment: "left",
                        visible: false,
                        allowEditing: false,
                    },
                    {
                        caption: "servicecodeOrd",
                        dataField: "servicecodeOrd",
                        sortOrder: "asc",
                        allowSorting: false,
                        alignment: "left",
                        visible: false,
                        allowEditing: false,
                    },
                    {
                        caption: "서비스코드명",
                        dataField: "servicecodeNm",
                        allowSorting: false,
                        alignment: "left",
                        allowEditing: true,
                        validationRules: [{ type: "required", message: "서비스코드명 입력은 필수입니다" }]
                    },
                    {
                        caption: "서비스코드",
                        dataField: "servicecode",
                        allowSorting: false,
                        alignment: "center",
                        allowEditing: true,
                        validationRules: [{ type: "required", message: "서비스코드 입력은 필수입니다" }]
                    },
                    {
                        caption: "단계",
                        dataField: "depth",
                        allowSorting: false,
                        alignment: "center",
                        allowEditing: false,
                    },
                    {
                        caption: "공지여부",
                        dataField: "noticeFl",
                        allowSorting: false,
                        alignment: "center",
                        allowEditing: true,
                        lookup: {
                            dataSource: this.$_enums.common.stringUsedFlag.values,
                            valueExpr: "value",
                            displayExpr: "label"
                        }
                    },
                    {
                        caption: "설명",
                        dataField: "description",
                        allowSorting: false,
                        alignment: "left",
                        allowEditing: true,
                    },
                    {
                        caption: "사용여부",
                        dataField: "viewFl",
                        allowSorting: false,
                        alignment: "center",
                        allowEditing: true,
                        lookup: {
                            dataSource: this.$_enums.common.stringUsedFlag.values,
                            valueExpr: "value",
                            displayExpr: "label"
                        }
                    },
                    {
                        caption: "삭제",
                        cellTemplate: "removeTemplate",
                        alignment: "center",
                        allowEditing: false,
                        visible: true,
                        width: 50
                    },
                ]
            },
            dataGrid: {
                callApi: "CALL_CC_API",
                refName: 'templatesRef',
                keyExpr: 'id',
                allowColumnResizing: true, //컬럼 사이즈 허용
                showBorders: false, //border 유무
                showColumnHeaders: true, //컬럼 헤더 유무
                showColumnLines: false, //컬럼 세로선 유무
                showRowLines: true, //컬럼 가로선 유무
                rowAlternationEnabled: false,
                dataSource: [],
                apiActionNm: {
                    // merge: 'SERVICE_TEMPLATE_LIST_MERGE',
                    // delete: 'SERVICE_TEMPLATE_LIST_DELETE',
                },
                showActionButtons: {
                    select: false,
                    copy: false,
                    update: false,
                    delete: false,
                    customButtons: [
                        {
                            widget: 'dxButton',
                            options: {
                                icon: '',
                                text: '등록',
                                elementAttr: { class: 'btn_XS default filled add1' },
                                width: 60,
                                height: 30,
                                onClick: () => {
                                    this.onClickTreeDropButtonItem();
                                },
                            },
                            location: 'before',
                        },
                        {
                            widget: 'dxButton',
                            options: {
                                icon: '',
                                text: '수정',
                                elementAttr: {class: 'btn_XS default filled add1'},
                                width: 60,
                                height: 30,
                                onClick: () => {
                                    this.openModal();
                                },
                            },
                            location: 'before',
                        },
                        {
                            widget: 'dxButton',
                            options: {
                                icon: '',
                                text: '삭제',
                                elementAttr: {class: 'btn_XS white light_filled trash'},
                                width: 60,
                                height: 30,
                                onClick: () => {
                                    this.deleteTemplate();
                                },
                            },
                            location: 'before',
                        },
                    ], // 그리드 커스텀 버튼 생성
                },
                grouping: {
                    contextMenuEnabled: false,
                    autoExpandAll: false,
                    allowCollapsing: true,
                    expandMode: 'rowClick' // rowClick or buttonClick
                },
                groupPanel: {
                    visible: false,
                },
                columnChooser: {
                    enabled: false, // 컬럼 Chooser 버튼 사용유무
                },
                loadPanel: {
                    enabled: true, // 로딩바 표시 유무
                },
                sorting: {
                    mode: 'multiple' // single multiple
                },
                // scrolling: { // 미사용시 주석처리
                //     mode: 'virtual' //스크롤 모드 : ['infinite', 'standard', 'virtual']
                // },
                remoteOperations: { // 서버사이드 여부
                    filtering: true,
                    sorting: true,
                    grouping: true,
                    paging: false,
                },
                paging: { // scrolling 미사용시만 적용됨
                    enabled: false,
                    pageSize: 10,
                    pageIndex: 0 // 시작페이지
                },
                pager: {
                    visible: false, //페이저 표시 여부
                    showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
                    allowedPageSizes: [],
                    displayMode: "compact", //표시 모드 : ['full', 'compact']
                    showInfo: false, //페이지 정보 표시 여부 : full인 경우만 사용 가능
                    showNavigationButtons: false //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
                },
                filterRow: {
                    visible: true,
                    operationDescriptions: {
                        contains: '포함'
                    }
                },
                headerFilter: {
                    visible: false,
                },
                editing: {
                    allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
                    allowDeleting: false,
                    allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
                    mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
                    startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
                    selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
                },
                customEvent: {
                    // saving: true,
                    rowClick: true,
                },
                selecting: {
                    mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
                    selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
                    showCheckBoxesMode: 'onClick', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
                },
                columns: [
                    {
                        caption: 'ID',
                        dataField: 'id',
                        width: 70,
                        height: 40,
                        alignment: 'center', // left center right
                        visible: false,
                        allowEditing: false,
                        sortOrder: 'none', // acs desc none
                        allowHeaderFiltering: false,
                        fixed: false, // 컬럼 fix 시 사용
                        fixedPosition: 'left', // left or right
                    },
                    {
                        caption: '서비스템플릿 목록',
                        dataField: 'svctpNm',
                        height: 40,
                        alignment: 'center',
                        visible: true,
                        allowEditing: true,
                        sortOrder: 'none',
                        allowHeaderFiltering: false,
                        allowGrouping: false,
                    },
                    {
                        caption: '설명',
                        dataField: 'description',
                        width: 120,
                        height: 40,
                        alignment: 'center',
                        visible: false,
                        allowEditing: true,
                        sortOrder: 'none',
                        allowHeaderFiltering: false,
                        allowGrouping: false,
                    },
                    {
                        caption: '순서',
                        dataField: 'svctpOrd',
                        width: 120,
                        height: 40,
                        alignment: 'center',
                        visible: false,
                        allowEditing: true,
                        sortOrder: 'none',
                        allowHeaderFiltering: false,
                        allowGrouping: false,
                    },
                    {
                        caption: '사용여부',
                        dataField: 'viewFl',
                        width: 120,
                        height: 40,
                        alignment: 'center',
                        visible: false,
                        allowEditing: true,
                        sortOrder: 'none',
                        allowHeaderFiltering: false,
                        allowGrouping: false,
                    },
                ]
            },
            noticeFlLookup: {
                dataSource: [],
                displayExpr: 'label',
                valueExpr: 'value',
            },
            useFlLookup: {
                dataSource: [],
                displayExpr: 'label',
                valueExpr: 'value',
            },
            showActionButtons: {
                select: false,
            },
            // 화면에 그리기 위해 사용하는 데이터
            view: {
                clickedMenuIds: [],       //템플릿 메뉴 버튼을 클릭할 경우 클릭한 템플릿 메뉴 Id arr
                allMenus: [],
                currentClickMenuButtons: {  //현재 클릭한 템플릿 메뉴 버튼 정보
                    button: {},
                    menuIdx: null,
                    buttonIdx: null,
                },
            },
            // 모달 관련된 객체 정보
            modal: {
                ModalTemplate: {
                    name: 'ModalTemplate',
                    contents: {
                        title: '서비스 템플릿',
                        buttons: {
                            save: {text: '저장'},
                            cancel: {text: '취소'},
                        },
                        width: '600',
                        height: '400',
                    },
                },

                isOpened: false,
                currentComponent: null,     // 현재 modal의 modal 명 ( 해당 값을 넘겨줘야 함 )
                currentSaveType: "INSERT",  // 현재 modal이 INSERT인지 UPDATE인지 구분이 필요. 그에 따라 API URL이 변경되어야 함.
                component: {},
                initData: {},
                templateContent: {},      // modal-template 와 데이터를 주고받기위해 사용하는 값
                maxOrd: 0,                // 순서 조정시 사용할 max 값
            },
            isReordered: false,
            clickedRow: [],
            clickedRowIndex: undefined,
            checkBoxValue: true,
            defaultVisible: false,
            popover_caption: '',
            popover_description: '',
            hoverTarget: '',
            childrenCntList: [], // 순서값 계산을 위한 변수(자식 Cnt값)
        }
    },
    computed: {},

    methods: {
        /** @description: 수정 클릭시 이벤트 */
        openModal() {
            this.modal.templateContent = this.$refs.templatesRef.selectedRowsData[0];
            this.onOpenModal(this.modal.ModalTemplate.name, this.config.mode.update);
        },
        /* @desc: 현재 모달 상태값 설정 */
        isOpenModal(data) {
            this.modal.isOpened = data;
            if (!data) {
                this.setInitModal();
            }
        },
        addNewRow() {
            this.$refs["serviceList"].instance.addRow();
        },
        /* @desc  Modal 이 열릴 때, Modal 에 전달할 값 셋팅
         * @params componentNm : Modal name
         * @params saveType : INSERT, UPDATE */
        onOpenModal(componentNm, saveType) {
            this.modal.currentComponent = componentNm;                      // 보여줄 Modal의 명
            this.modal.initData = this.modal[componentNm].contents; // this.modal.COMPONENTNAME 으로 명시된 값의 속성으로 modal 의 크기를 결정 함.
            this.modal.currentSaveType = saveType;                         // 현재 모달이 Insert 목적인지, Update 목적인지에 따라, 호출하는 API를 동적으로 사용하기 위해 사용
            this.isOpenModal(true);
        },
        /* @desc: Close modal */
        async closeModalAndReloadData() {
            await this.setInitModal();
        },

        /* @description : 페이지 인입시 메서드 */
        async initPage() {
            this.treeGrid.dataSource = [];
            await this.setInitModal();
            await this.selectServiceTemplates(this.treeGrid.currentSort);
        },

        /* @desc: 모달창이 close될 때, modal 객체 설정 값 초기화 */
        setInitModal() {
            this.modal.isOpened = false;
            this.modal.currentComponent = null;
            this.modal.initData = {};
        },

        /* @desc: Tree 에서 우측의 Drop button 클릭 시, Template 에 대한 정보를 저장하기 위한 모달을 띄움
         *         해당 이벤트 발생 전, onClickTreeRow 이벤트가 먼저 발생 됨 */
        onClickTreeDropButtonItem(clickEvent) {
            console.log("onClickTreeDropButtonItem");
            console.log(clickEvent);
            if (!clickEvent || clickEvent.itemData === '등록') {
                this.onOpenModal(this.modal.ModalTemplate.name, this.config.mode.insert);
            } else if (clickEvent.itemData === '삭제') {
                this.deleteTemplate();
            } else if (clickEvent.itemData === '수정') {
                this.onOpenModal(this.modal.ModalTemplate.name, this.config.mode.update);
            }
        },

        /* @desc: Tree header selectbox로 순서 변경 */
        async onChangedSort(e) {
            this.treeGrid.currentSort = e.value;
            await this.selectServiceTemplates(this.treeGrid.currentSort).then(res => {
                let checked = {};
                checked = {value: this.checkBoxValue};
                this.isUsedFlag(checked);
                if (this.clickedRowIndex !== undefined && this.clickedRowIndex !== -1) {
                    this.treeGrid.dataSource = this.treeGrid.templateList[this.clickedRowIndex].ivrSvc;
                }
            });
        },

        onInitNewRow(e) {
            e.component.expandRow(78);
            e.component.refresh();
            e.data.svctpId = this.clickedRow.id;
            e.data.noticeFl = 'N';
            e.data.viewFl = 'Y';
            e.data.servicecodeOrd = 1;
            e.data.depth = 1;

            if (e.data.parentId !== -1) {
                let parentNode = e.component.getVisibleRows().find(row => row.data.id === e.data.parentId);
                let cnt = 1;
                cnt += parentNode.node.children.length;
                e.data.depth += parentNode.data.depth;

                var foundIndex = this.childrenCntList.findIndex(item => item.id === e.data.parentId);
                if (foundIndex !== -1) {
                    cnt += this.childrenCntList[foundIndex].cnt++;
                } else {
                    this.childrenCntList.push({id: e.data.parentId, cnt: 1});
                }
                e.data.servicecodeOrd = cnt;

            } else {
                e.data.servicecodeOrd = e.component.getVisibleRows().filter(row => (row.data.parentId === null || row.data.parentId === -1)).length + 1
            }
        },
        async onEditCanceling(e) {
            this.childrenCntList = [];
            this.isReordered = false;
            await this.selectServiceTemplates(this.treeGrid.currentSort).then(res => {
                let checked = {};
                checked = {value: this.checkBoxValue};
                this.isUsedFlag(checked);
                if (this.clickedRowIndex !== undefined && this.clickedRowIndex !== -1) {
                    this.treeGrid.dataSource = this.treeGrid.templateList[this.clickedRowIndex].ivrSvc;
                }
            });
            document.querySelector('#addRootBtn').classList.add('dx-state-disabled');
        },
        async onSaving(e) {
            // false 셋팅하면 grid에 binding된 data가 변경되어버림
            e.cancel = true;
            if (e.changes.length) {
                let data = [];

                if (e.changes && e.changes.length > 0) {
                    e.changes.forEach(d => {
                        this.$log.debug('e.changes data', d.data);
                        if (d.type === 'update') {
                            d.data.editId = this.$store.getters.getLoginId; //수정자
                            data.push({id: d.key, ...d.data});
                        } else if (d.type === 'insert') {
                            if (Object.keys(d.data).length !== 0) {
                                d.data.regId = this.$store.getters.getLoginId; //등록자
                                data.push({id: null, ...d.data});
                            }
                        }
                    });
                } else {
                    for (let index = 0; index < this.treeGrid.dataSource.length; index++) {
                        this.treeGrid.dataSource[index].servicecodeOrd = index;
                    }
                    data = this.treeGrid.dataSource;
                }
                let payload = {
                    actionname: this.config.api.ivrSvcUpdate,
                    data: data,
                    loading: true,
                };
                let res = await this.CALL_CC_API(payload);
                if (res.status == '200') {
                    if (res.data.header.resCode === 'success') {
                        e.component.cancelEditData();
                        // this.refreshData();
                        this.$_Toast('저장되었습니다.');
                        await this.selectServiceTemplates(this.treeGrid.currentSort).then(res => {
                            let checked = {};
                            checked = {value: this.checkBoxValue};
                            this.isUsedFlag(checked);
                            this.treeGrid.dataSource = this.treeGrid.templateList[this.clickedRowIndex].ivrSvc;
                        });
                    } else if (res.data.header.resCode === 'fail' && res.data.header.resMsg.includes('.U_CODE_U01')) {
                        this.$_Toast('같은 부모의 하위코드 키와 값은 중복될 수 없습니다.');
                    } else {
                        this.$_Msg('저장 실패');
                    }
                } else {
                    this.$_Msg('저장 실패');
                    e.component.cancelEditData();
                }
            }
        },
        async onSaved(e) {
            await this.onSelect();
        },
        async onSelect() {
            await this.selectServiceTemplates(this.treeGrid.currentSort).then(res => {
                if (this.clickedRowIndex !== undefined && this.clickedRowIndex !== -1) {
                    this.treeGrid.dataSource = this.dataGrid.dataSource[this.clickedRowIndex].ivrSvc;
                }
            });
        },

        /* @desc: 순서 저장 */
        onSaveSort() {
            if (this.isReordered !== true) {
                return this.$_Toast('순서가 변경된 내역이 없습니다.');
            }
            // for (let index = 0; index < this.treeGrid.dataSource.length; index++) {

            this.savaTreeSorting(this.treeGrid.dataSource);
        },

        async savaTreeSorting(dataList) {
            let data = dataList;
            let payload;

            payload = {
                actionname: this.config.api.ivrSvcUpdate,
                data: {data: data},
                loading: true,
            };

            if (await this.$_Confirm('현재 순서를 저장하시겠습니까?')) {
                const res = await this.CALL_CC_API(payload);
                if (isSuccess(res)) {
                    if (await this.$_Toast('적용되었습니다')) {
                        this.$_setSessionStorageMenu(); //메뉴 sessionStorage 재설정
                        this.isReordered = false;
                    }
                }
            }
        },

        selectChildrenById(arr, key) {
            const result = this.findIdAndChildren(arr, key);
            return result ? result : {id: key, childs: []};
        },

        findIdAndChildren(arr, key) {
            let output = null;

            arr.forEach(o => {
                if (o.data.id === key) {
                    output = {
                        id: o.data.id,
                        childs: (o.children && this.getArrayOfChildren(o.children)) || [],
                    };
                } else if (!output && o.children && o.children.length) {
                    const childOutput = this.findIdAndChildren(o.children, key);
                    if (childOutput) {
                        output = {
                            id: childOutput.id,
                            childs: childOutput.childs,
                        };
                    }
                }
            });

            return output;
        },

        getArrayOfChildren(arr) {
            let existingChildren = [];
            arr.forEach(o => {
                existingChildren.push(o.data.id);
                o.children && existingChildren.push(...this.getArrayOfChildren(o.children));
            });

            return existingChildren;
        },


        async onDeleteData(data) {

            let childrenArr = this.selectChildrenById([data.row.node], data.row.node.key);
            let deletedIds = [childrenArr.id, ...childrenArr.childs].map(d => {
                return {id: d};
            });

            deletedIds.reverse();

            let msgContents;
            if (data.row.node.children.length > 0) {
                msgContents = `선택한 데이터를 삭제하시겠습니까? <br> 삭제를 진행할 경우 하위 레벨의 코드데이터도 삭제됩니다.`;
            } else {
                msgContents = `선택한 데이터를 삭제하시겠습니까?`;
            }

            if (await this.$_Confirm(msgContents)) {
                this.deleteServiceList(deletedIds);
            } else {
                return false;
            }
        },

        async deleteServiceList(deletedIds) {
            let payload;
            let res;

            payload = {
                actionname: this.config.api.ivrSvcDelete,
                data: deletedIds,
                loading: true,
            };

            res = await this.CALL_CC_API(payload);

            if (res.status === 200) {
                if (res.data.header.resCode === 'success') {
                    this.$_Toast(this.$_msgContents('CMN_SUC_DELETE'));

                    await this.selectServiceTemplates(this.treeGrid.currentSort).then(res => {
                        let checked = {};
                        checked = {value: this.checkBoxValue};
                        this.isUsedFlag(checked);
                        this.treeGrid.dataSource = this.treeGrid.templateList[this.clickedRowIndex].ivrSvc;
                    });
                } else {
                    this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
                }
            }
        },

        onDragChange(e) {
            const visibleRows = e.component.getVisibleRows();
            const sourceNode = e.component.getNodeByKey(e.itemData.id);
            let targetNode = visibleRows[e.toIndex].node;

            if (sourceNode.data.parentId !== targetNode.data.parentId) {
                e.cancel = true;
            }
        },
        onReorderRow(e) {
            let vm = this;
            const visibleRows = e.component.getVisibleRows();
            const toDataId = visibleRows[e.toIndex].data.id;
            let ordIndex = 1;
            let reorderRows;
            if (e.itemData.parentId !== null) {
                reorderRows = visibleRows.find(row => row.data.id === e.itemData.parentId).node.children;
            } else {
                reorderRows = visibleRows.filter(row => row.data.parentId === null);
            }
            const fromData = reorderRows.find(row => row.data.id === e.itemData.id);
            const fromDataIndex = reorderRows.findIndex(row => row.data.id === e.itemData.id);
            const toDataIndex = reorderRows.findIndex(row => row.data.id === toDataId);
            reorderRows.splice(fromDataIndex, 1);
            reorderRows.splice(toDataIndex, 0, fromData);

            reorderRows.forEach(child => {
                child.data.servicecodeOrd = ordIndex++;
            });

            e.component.refresh();

            this.isReordered = true;
            document.querySelector('#addRootBtn').classList.remove('dx-state-disabled');
        },
        async onRowRemoved() {
            await this.onSelect();
        },
        onRowRemoved(){
            this.selectServiceTemplates();
        },
        /* @desc: template 삭제 */
        async deleteTemplate() {

            let checkIvrDnisByTemplate = false;
            let payload = {
                actionname: this.config.api.ivrDnisGet,
                data: {svctpId: this.clickedRow.id},
            }

            let res = await this.CALL_CC_API(payload);
            if (res.status === 200) {
                if (res.data.header.resCode === "success") {
                    if (res.data.data.length > 0) {
                        checkIvrDnisByTemplate = true;
                    }
                } else {
                    this.$_Toast('작업 실패');
                    return false;
                }
            }

            if (checkIvrDnisByTemplate) {
                this.$_Toast('IVR Dnis에 할당된 템플릿은 삭제할 수 없습니다.');
                return false;
            } else {
                const data = this.$refs.templatesRef.selectedRowsData;
                let deletedIds = data.map(d => d.id);
                let msgContents;

                if (deletedIds.length > 0) {
                    msgContents = `선택한 데이터를 삭제하시겠습니까? <br> 삭제를 진행할 경우 하위 레벨의 코드데이터도 삭제됩니다.`;
                } else {
                    msgContents = `선택한 데이터를 삭제하시겠습니까?`;
                }

                if (await this.$_Confirm(`템플릿에 할당 된 서비스코드 정보도 삭제됩니다. 삭제하시겠습니까?`)) {
                    let payload = {
                        actionname: this.config.api.serviceTemplatesDelete,
                        data: {data: deletedIds},
                        loading: true,
                    }

                    let res = await this.CALL_CC_API(payload);
                    this.checkApiResponse(res, '템플릿 정보가 삭제되었습니다.');
                }
            }
        },

        /* @description: template 팝업 확인 버튼 이벤트 */
        onConfirmModal(e) {
            this.setRegOrEditId();                // reg or edit user id 설정

            //해당 모달 컴포넌트에서 데이터 저장
            let promise = new Promise((resolve, reject) => {
                this.$_eventbus.$emit(`${this.modal.currentComponent}:onSaveData`, e, resolve, reject);
            })

            promise.then((res) => {
                // use res
                if (res === 200) {
                    this.selectServiceTemplates(this.treeGrid.currentSort).then(res => {
                        let checked = {};
                        checked = {value: this.checkBoxValue};
                        this.isUsedFlag(checked);
                        this.clickedRowIndex = undefined;
                        this.treeGrid.dataSource = [];
                        this.$refs['templateList'].instance.deselectAll();
                        document.querySelector('#addRootBtn').classList.add('dx-state-disabled');
                    });
                    this.setInitModal();
                } else {
                    this.$_Toast('작업 실패');
                }
            }).catch((err) => {
                // handle error
                this.$_Toast('저장 작업 중 오류가 발생했습니다.');
            })
        },

        // Set rigister of edit user id
        setRegOrEditId() {
            if (this.modal.currentSaveType === this.config.mode.insert) {
                this.modal.templateContent.regId = this.$store.getters.getLoginId;
            } else {
                this.modal.templateContent.editId = this.$store.getters.getLoginId;
            }
        },

        /* @description: 사용중인 코드만 보기 체크 */
        async isUsedFlag(e) {
            if (e.value) {
                let serviceList = this.$refs['serviceList'].instance;
                let filterList = this.treeGrid.templateList.filter(d => d.viewFl === 'Y');
                if (!filterList.find(item => item.id === this.clickedRow.id)) {
                    if (serviceList.hasEditData() || this.isReordered) {
                        if (await this.$_Confirm('템플릿 수정사항이 취소됩니다. 사용중인 템플릿만 보시겠습니까?')) {
                            serviceList.cancelEditData();
                            this.treeGrid.dataSource = [];
                            document.querySelector('#addRootBtn').classList.add('dx-state-disabled');
                        } else {
                            this.$refs['useCheckBox'].instance.resetOption("value")
                            return;
                        }
                    } else {
                        this.treeGrid.dataSource = [];
                        document.querySelector('#addRootBtn').classList.add('dx-state-disabled');
                    }
                }
                this.treeGrid.templateList = this.treeGrid.templateList.filter(d => d.viewFl === 'Y');
            } else {
                this.treeGrid.templateList = this.treeGrid.originTemplateList;
            }
            this.clickedRowIndex = this.treeGrid.templateList.findIndex(item => item.id === this.clickedRow.id);
            this.checkBoxValue = e.value;
        },


        // Click event on left tree row
        /* @description : 트리 로우 클릭 이벤트 */
        async onClickTreeRow(row) {
            let serviceList = this.$refs.serviceList.instance;
            console.log(serviceList);
            if (this.clickedRow.id !== undefined && this.clickedRow.id !== row.data.id && this.isReordered) {
                if (await this.$_Confirm('템플릿 수정사항이 취소됩니다. 템플릿을 변경 하시겠습니까?')) {
                    serviceList.cancelEditData();
                } else {
                    this.$refs['templateList'].instance.selectRowsByIndexes(this.clickedRowIndex);
                    return;
                }
            }
            if (row.isSelected) {
                this.treeGrid.dataSource = row.data.ivrSvc;
                this.clickedRow = row.data;
                this.clickedRowIndex = row.rowIndex;
                document.querySelector('#addRootBtn').classList.remove('dx-state-disabled');
                this.modal.templateContent = row.data;
                console.log(this.treeGrid);
            } else {
                this.treeGrid.dataSource = [];
                this.clickedRow = [];
                this.clickedRowIndex = undefined;
                document.querySelector('#addRootBtn').classList.add('dx-state-disabled');
            }
        },
        /* @description : 트리 헤더 재설정 */
        onCellPrepared(cellInfo) {
            let element = cellInfo.cellElement;
            if (cellInfo.rowType === 'header') {    //header cell
                element.style.textAlign = 'center';
                if (cellInfo.columnIndex === 0) {
                    element.setAttribute('colspan', 2);
                } else {
                    element.style.display = 'none';
                }
            }
        },
        onCellHoverChanged(e) {
            this.defaultVisible = false;
            if (e.rowType == 'data') {
                if (e.eventType === 'mouseover') {
                    this.hoverTarget = e.row.cells[0].cellElement;
                    this.popover_caption = e.row.data.svctpNm;
                    this.popover_description = e.row.data.description;
                    this.defaultVisible = true;
                    this.hoverTarget.style.backgroundColor = "#f5f5f5";
                    e.row.cells[1].cellElement.style.backgroundColor = "#f5f5f5";
                } else if (e.eventType === 'mouseout') {
                    this.hoverTarget.style.backgroundColor = "";
                    e.row.cells[1].cellElement.style.backgroundColor = "";
                }
            }
        },

        /* @desc: response 체크 중복되는 로직 제거를 위해 사용 */
        async checkApiResponse(response, message) {
            if (response.status === 200) {
                if (response.data.header.resCode === "success") {
                    if (this.$_Toast(message)) {
                        await this.selectServiceTemplates(this.treeGrid.currentSort).then(res => {
                            let checked = {};
                            checked = {value: this.checkBoxValue};
                            this.isUsedFlag(checked);
                            this.clickedRowIndex = undefined;
                            this.treeGrid.dataSource = [];
                            this.$refs['templateList'].instance.deselectAll();
                            document.querySelector('#addRootBtn').classList.add('dx-state-disabled');
                        });
                    }
                } else {
                    this.$_Toast('작업 실패');
                }
            }
        },
        /* @description : 템플릿 데이터 조회 */
        async selectServiceTemplates(sort = "-svctpOrd") {
            let data = {sort};
            let payload = {
                actionname: this.config.api.serviceTemplatesGet,
                data: {params: data},
                loading: true,
            }
            let res = await this.CALL_CC_API(payload);
            if (res.status === 200) {
                if (res.data.header.resCode === "success") {
                    this.dataGrid.dataSource = res.data.data.filter(d => d.viewFl === 'Y');
                    this.treeGrid.originTemplateList = res.data.data;
                    this.modal.maxOrd = res.data.data.length;
                }
            }
        },
        /* @description : 데이터 없을시 출력 */
        noDataText(length) {
            if (length === 0) {
                return '추가된 템플릿이 없습니다.';
            }
        },
    },
    created() {
    },
    mounted() {
        this.initPage();
    },
}
</script>

<style>
.page-sub-box .pad_top20, .dx-datagrid-header-panel {
    padding: 0px !important;
    margin: 0px !important;
}

/* 트리리스트 검색 패널 관련 */
#templates .dx-toolbar .dx-toolbar-after {
    margin: 0 auto;
    padding-left: 0;
    left: 0;
    right: 0;
}

#templates .dx-row > td {
    padding: 0px;
}

#templates .dx-placeholder {
    padding-left: 20px;
}

#templates .dx-treelist-search-panel {
    margin: 0;
}

.dx-freespace-row {
    height: 0px !important;
}

.layout-rio-right {
    margin-left: 30px;
    width: calc(100% - 380px);
    height: 100%;
}

.addButtonColumn {
    font-size: 13px !important;
    color: #545454 !important;
    font-weight: 700 !important;
}

.dx-treelist .dx-link {
    text-decoration: none;
}

.dx-row {
    margin-top: 0px !important;
}

#sortingBox .dx-texteditor-input {
    text-align: center;
}

</style>
<style lang="scss" scoped>
.noformdata {
    padding-top: 50px;
    text-align: center;
}

.noborder {
    border: none;
}

.dropDownButton.overflow {
    padding-left: 0;
}

.dropDownButton .dx-button-mode-text .dx-icon {
    text-align: center;
    color: #9a9a9a;
}

.template-card-box .dropDownButton {
    position: absolute;
    right: -20px;
}

.template-card-box .dropDownButton .dx-button-mode-text .dx-icon {
    color: black;
}

.sub_new_style01 .page_search_box .inner2 div {
    display: inline-block;
}

.sub_new_style01 .page_search_box .inner2 > div {
    vertical-align: middle;
    margin-right: 10px;
}

.template-card-box .template-card-body .template-card-main .template-m-img1,
.template-card-box .template-card-body .template-card-main .template-m-img2 {
    min-height: 115px;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    background-color: #4770cc;
}

.template-card-box .template-card-body .template-card-main .template-m-img1:after,
.template-card-box .template-card-body .template-card-main .template-m-img2:after {
    background: none;
}

.template-card-box .template-card-body .template-card-main .template-m-img1 img,
.template-card-box .template-card-body .template-card-main .template-m-img2 img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
}

.dx-treelist::v-deep .dx-treelist-content .dx-treelist-table .dx-row > td {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.tree-box01 {
    width: 25%;
    margin-right: 20px !important;
}

.tree-box02 {
    width: 75%;
    height: 100% !important;
}

.dx-treelist::v-deep .dx-treelist-header-panel {
    padding: 0px !important;
}
.selectDiv {
    padding-top: 20px;
    margin-bottom: 10px;
}
</style>